const strings = {
    sv: {
        'Zoezi Developer Portal': 'Zoezi Utvecklarportal',
        'Add developer': 'Lägg till utvecklare',
        'E-mail': 'E-post',
        'Name': 'Namn',
        'First name': 'Förnamn',
        'Last name': 'Efternamn',
        'Cancel': 'Avbryt',
        'Save': 'Spara',
        'My addons': 'Mina tillval',
        'Usage': 'Användning',
        'Sales': 'Försäljning',
        'Settings': 'Inställningar',
        'Log out': 'Logga ut',
        'Permissions the addon will be granted': 'Behörigheter som tillvalet kommer ges',
        'E-mail to support': 'E-post till support',
        'Language': 'Språk',
        'Remove': 'Ta bort',
        'SSH keys': 'SSH-nycklar',
        'SSH public key': 'Publik SSH-nyckel',
        'Review': 'Granska',
        'Add SSH key': 'Lägg till SSH-nyckel',
        'No SSH keys found': 'Inga SSH-nycklar hittades',
        'Cancel': 'Avbryt',
        'Error': 'Fel',
        'Could not add SSH key. Make sure the key length is enough. For RSA, minimum length is 3071 chars.': 'Kunde inte lägga till SSH-nyckel. Se till att nyckellängden är tillräcklig. För RSA är det 3071 tecken.',
        'Could not remove SSH key': 'Kunde inte ta bort SSH-nyckel',
        'Enable Git': 'Aktivera Git',
        'Could not enable Git': 'Kunde inte aktivera Git',
        'Git is enabled': 'Git är aktiverat',
        'New password': 'Nytt lösenord',
        'Change password and log in': 'Byt lösenord och logga in'
    },
    // For English, most strings are the same, so they aren't added here
    en: {
    }
}

class TranslationApi {
    translate(x) { // Accessible as $translate or window.$translate
        let language = this.$root?.language || 'sv';
        return strings[language][x] || x;
    }

    setLanguage(language) {
        this.$root.language = language;
        try {
            window.localStorage.setItem('zoeziLanguage', language)
        }
        catch {
            // pass
        }
    }

    setVueRoot($root) {
        this.$root = $root;
        try {
            let language = window.localStorage.getItem('zoeziLanguage')
            if (language) {
                this.$root.language = language;
            }
        }
        catch {
            // pass
        }

        $root.$watch('language', (newValue, oldValue) => {
            if (newValue && newValue != oldValue) {
                console.log('Changing language in watch')
                this.setLanguage(newValue);
            }
        })
    }

    constructor(Vue) {
        this.translations = {};
        this.language = 'sv';
        Vue.prototype.$translations = this;
        Vue.prototype.$translate = (x) => this.translate(x)
        Vue.filter('translate', (x) => this.translate(x));
        window.$translate = (x) => this.translate(x);
    }
}

export default {
    install(Vue) {
        new TranslationApi(Vue)
    }
}
